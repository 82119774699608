(function () {
    'use strict';
    angular
        .module('app')
        .component('numericKeyboard', {
            bindings: {
                showDecimal: '<',
                onKeyPress: '&'
            },
            templateUrl: 'app/directives/numeric-keyboard.html',
            controller: function ($rootScope) {
                this.keys = [1, 2, 3, 4, 5, 6, 7, 8, 9];
                var lastFocusedElement = null; // Para almacenar el último input enfocado

                // Método para insertar números en el input actualmente enfocado
                this.handleKeyPress = function (key) {
                    if (lastFocusedElement) {
                        lastFocusedElement.focus();

                        var currentValue = lastFocusedElement.value;
                        var start = lastFocusedElement.selectionStart;
                        var end = lastFocusedElement.selectionEnd;

                        lastFocusedElement.value = currentValue.slice(0, start) + key + currentValue.slice(end);
                        lastFocusedElement.selectionStart = lastFocusedElement.selectionEnd = start + 1;

                        angular.element(lastFocusedElement).triggerHandler('input');
                    }
                };

                // Método para borrar el último carácter en el input con el foco
                this.handleDelete = function () {
                    if (lastFocusedElement) {
                        lastFocusedElement.focus();
                        var currentValue = lastFocusedElement.value;
                        var start = lastFocusedElement.selectionStart;
                        var end = lastFocusedElement.selectionEnd;

                        if (start !== end) {
                            lastFocusedElement.value = currentValue.slice(0, start) + currentValue.slice(end);
                        } else if (start > 0) {
                            lastFocusedElement.value = currentValue.slice(0, start - 1) + currentValue.slice(end);
                            lastFocusedElement.selectionStart = lastFocusedElement.selectionEnd = start - 1;
                        }

                        angular.element(lastFocusedElement).triggerHandler('input');
                    }
                };

                // Método para cerrar el teclado
                this.handleClose = function () {
                    if (lastFocusedElement) {
                        lastFocusedElement.blur();
                        lastFocusedElement = null;
                        this.onKeyPress({ key: 'close' });
                    }
                };

                this.handlAddPlays = function () {
                    if (lastFocusedElement) {
                        lastFocusedElement.blur();
                        lastFocusedElement = null;
                        this.onKeyPress({ key: 'addPlays' });
                    }
                };

                this.handleNext = function () {
                    var numberPlaysElement = document.querySelector('#numberPlays');
                    var amountElement = document.querySelector('#amount');

                    if (lastFocusedElement === amountElement) {
                        var value = (amountElement.value || '').trim();

                        if (value !== '') {
                            $rootScope.$emit('amountReady', {
                                from: 'handleNext',
                                value: value
                            });
                        }

                        return;
                    }

                    if (lastFocusedElement && numberPlaysElement && numberPlaysElement.id === 'numberPlays') {
                        var value = numberPlaysElement.value.trim();

                        if (value[value.length - 1] === '.') {
                            value = value.slice(0, -1);
                        }

                        var plays = value.split('.');

                        var allValid = plays.every(function (play) {
                            return /^\d{3}$/.test(play);
                        });

                        if (!allValid) {
                            alert('Todas las jugadas deben tener exactamente 3 dígitos.');
                            return;
                        }

                        if (amountElement) {
                            amountElement.focus();
                            var amountValue = amountElement.value || '';
                            amountElement.setSelectionRange(amountValue.length, amountValue.length);
                        }
                    }
                };

                // Evento para detectar cuál es el último input enfocado
                document.addEventListener('focusin', function (event) {
                    var target = event.target;
                    if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {
                        lastFocusedElement = target;
                    }
                });
            }
        });
})();
