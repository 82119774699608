angular
.module('app')
.component('miniJungla', {
  template: '<div ng-include="$ctrl.urlTemplate"></div>',
  controller: MinijunglaController,
  bindings: {
    drawsZoo: '<',
    animalitosJungla: '=',
    drawsWithPlays: '=',
    products: '=',
    onAddPlays: '&',
    onSelectDrawByProduct: '&',
    amount: '=',
    onSale: '&',
    onClearAnimalitos: '&',
    onShowKeyboard: '&',
    onDeleteAnimal: '&',
  }
});

MinijunglaController.$inject = ['user', 'animalitoService', 'miscelaneos'];

function MinijunglaController($user, $animalitoService, $miscelaneos) {
  var vm = this;
  vm.color = '#54a23f';
  vm.countDraw;
  vm.plays = [];
  vm.step1 = true;
  vm.step2 = false;
  vm.step3 = false;
  vm.showAnimals = true;
  vm.producto_id = $miscelaneos.getProductIdByGame('Jungla');
  vm.date = moment().format('DD/MM/YY');
  var day = moment().format('d');
  var hour = moment().format('H:mm');


  vm.$onInit = function(){
    console.log(vm.amount)
    vm.loading = true;
    vm.currentUser = $user.getCurrentUser();
    if(window.orientationScreen()){
      vm.urlTemplate = 'app/components/jungla/jungla-portrait.html';
    }else{
      vm.urlTemplate = 'app/components/jungla/mini-jungla.html';
    }
  }

  vm.executeAddPlays = function() {
    vm.onAddPlays();
  };

  vm.handleSelectDrawByProduct = function(draw) {
    vm.onSelectDrawByProduct({ product: draw });
  };

  vm.handleClearAnimals = function(){
    console.log('clear')
    vm.onClearAnimalitos();
  }

  vm.handleShowKeyboard = function(event){
    vm.onShowKeyboard({event: event});
  }

  vm.handleDeleteAnimal = function(id, animalito, index){
    vm.onDeleteAnimal({id: id, animalito: animalito, index: index});
  }

}
