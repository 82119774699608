(function () {
  'use strict';
  angular
  .module('app')
  .component('lobbyComponent', {
    templateUrl: 'app/components/lobby/lobby.html',
    controller: MainComponentController,
    bindings: {
      productsAvailables: '<'
    }
  });

 MainComponentController.$inject = ['user','$timeout', '$state','$rootScope','cashierService','$window','$uibModal'];

  function MainComponentController($user, $timeout, $state, $rootScope, $cashierService, $window, $uibModal) {
    var vm = this;
    vm.currentUser = $user.getCurrentUser();

    vm.$onInit = function() {
      vm.cashier_id = null;
      vm.currentUser.cajero = undefined;
      console.log(vm.currentUser.tipo_estructura == 'auto_servicio', vm.currentUser.modality == 'cashinCashout')
      if(vm.currentUser.tipo_estructura == 'auto_servicio' && vm.currentUser.modality == 'cashinCashout'){
        vm.activateCoupon();
      }
      $rootScope.$broadcast('updatedUser', vm.currentUser);
    }

    vm.taquillaConstruccion = function(){
      $window.swal('Alto','Juego en Construccion', 'warning')
    }

    vm.activateCoupon = function(){
      var modal = $uibModal.open({
        animation: true,
        component: 'modalActiveCashinCashout',
        windowClass: 'modalActiveCashinCashout',
        backdrop: 'static',
        keyboard: false,
        size: 'md',
      })

      modal.result.then(function(uuid){
        $cashierService.activeCoupon({code: uuid})
        .then(function(response){
          vm.cashier_id = response.cupon.cajero_web_id;
          vm.currentUser.cajero = response.cajero_web;
          $user.setCurrentUser(vm.currentUser);
          $rootScope.$broadcast('updateCupon', response.cupon);
        }, function(err){
          swal('Error', err.data.message, 'error');
          vm.activateCoupon();
        })
      }, function(value){
        if(value == 'dismiss'){
          activeModalityCashinCashout(true);
        }
      })
    }

    function activeModalityCashinCashout(data){
      if(data){
        $cashierService.toggleModality({id: vm.currentUser.id})
        .then(function(response){
          var user = vm.currentUser;
          user.modality = response.data.modality;
          $user.setCurrentUser(user);
          $rootScope.$emit('updatedUser', user);
          $timeout(function(){
            $window.location.reload(true);
          }, 1)
        })
      }
    }

    $rootScope.$on('activeCashinCashout', function(evt, data){
      activeModalityCashinCashout(data);
    })

    
    $rootScope.$on('updatedUser',function(event,data){
      vm.currentUser = data;
    });

    $rootScope.$on('updateCajeroUser', function(evt, data){
      delete vm.currentUser.cajero
      vm.currentUser.cajero = data;
      $user.setCurrentUser(vm.currentUser);
    })

    $rootScope.$emit('updateNameState', {state: $state.current.name});
  }

})();
