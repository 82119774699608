angular
.module('app')
.component('granjazo', {
  template: '<div ng-include="$ctrl.urlTemplate"></div>',
  controller: GranajazoController,
  bindings: {
    drawsZoo: '=',
    animalitosGranjazo: '=',
    drawsZoo: '=',
    drawsWithPlays: '=',
    products: '=',
    onAddPlays: '&',
    onSelectDrawByProduct: '&',
    amount: '=',
    onSale: '&',
    onClearAnimalitos: '&',
  }
});

GranajazoController.$inject = ['user', 'animalitoService', 'miscelaneos'];

function GranajazoController($user, $animalitoService, $miscelaneos) {
  var vm = this;
  vm.color = '#54a23f';
  vm.countDraw;
  vm.amount = undefined;
  vm.plays = [];
  vm.step1 = true;
  vm.step2 = false;
  vm.step3 = false;
  vm.showAnimals = true;
  vm.drawsWithPlays = [];
  vm.producto_id = $miscelaneos.getProductIdByGame('Granjazo');
  vm.tabPanel = 'first';
  vm.date = moment().format('DD/MM/YY');
  var day = moment().format('d');
  var hour = moment().format('H:mm');


  vm.$onInit = function(){
    vm.loading = true;
    vm.currentUser = $user.getCurrentUser();

    if(window.orientationScreen()){
      vm.urlTemplate = 'app/components/granjazo/granjazoPortrait.html';
    }else{
      vm.urlTemplate = 'app/components/granjazo/granjazo.html';
    }

  }

  vm.executeAddPlays = function() {
    vm.onAddPlays();
  };

  vm.handleSelectDrawByProduct = function(draw) {
    vm.onSelectDrawByProduct({ product: draw });
  };

  vm.handleClearAnimals = function(){
    vm.onClearAnimalitos();
  }


}
