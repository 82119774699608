angular
.module('app')
.component('lottoActivo', {
  template: '<div ng-include="$ctrl.urlTemplate"></div>',
  controller: LottoActivoController,
  bindings: {
    drawsZoo: '<'
  }
});

LottoActivoController.$inject = ['toastr', 'moment', 'animalitoService','user','$uibModal','$rootScope','$sce','$timeout','$interval','cashierService','$window','miscelaneos','$state'];

function LottoActivoController(toastr, moment, $animalitoService, $user, $uibModal, $rootScope,$sce,$timeout,$interval, $cashierService, $window, $miscelaneos, $state) {
  var vm = this;
  vm.color = '#54a23f';
  vm.countDraw;
  vm.amount = undefined;
  vm.plays = [];
  vm.sale = sale;
  vm.step1 = true;
  vm.step2 = false;
  vm.showAnimals = true;
  vm.drawsWithPlays = [];
  vm.addPlays = addPlays;
  vm.nextStep = nextStep;
  vm.clearDraws = clearDraws;
  vm.clearPlays = clearPlays;
  vm.cancelPlay = cancelPlay;
  vm.aceptPlays = aceptPlays;
  vm.deleteDraw = deleteDraw;
  vm.showTicket = showTicket;
  vm.deleteAnimal = deleteAnimal;
  vm.checkAnimalitos = checkAnimalitos;
  vm.clearAnimalitos = clearAnimalitos;
  vm.checkDrawSelected = checkDrawSelected;
  vm.animalitos = $animalitoService.getAnimals('lotto-activo');
  vm.producto_id = 8;
  vm.date = moment().format('DD/MM/YY');
  var day = moment().format('d');
  var hour = moment().format('H:mm');


  vm.$onInit = function(){
    vm.currentUser = $user.getCurrentUser();
    vm.config_lot = {
      mpj: parseInt(vm.currentUser.super_juegos.conf_lot.mpj),
      jpt: parseInt(vm.currentUser.super_juegos.conf_lot.jpt),
      mmt: parseInt(vm.currentUser.super_juegos.conf_lot.mmt),
      mt: parseInt(vm.currentUser.super_juegos.conf_lot.mt)
    };
    checkCoupons();
    if(window.mobileAndTabletcheck()){
      vm.urlTemplate = 'app/components/lotto-activo/lotto-activo-mobile.html';
    }else{
      vm.urlTemplate = 'app/components/lotto-activo/lotto-activo.html';
    }

  }

  function addPlays(){
    if(validateAmountMult()){
      if(vm.checkDrawSelected()){
        if(vm.amount){
          if(vm.checkAnimalitos()){
            _.forEach(vm.drawsZoo, function(draw){
              if(draw.checked){
                var findDraw = _.findWhere(vm.drawsWithPlays, {id: draw.id});
                if(findDraw){
                  var animalitos = getAnimalitos();
                  _.forEach(animalitos, function(ani){
                    var anim = _.findWhere(findDraw.plays, {n: ani.n});
                    if(anim){
                      anim.m = parseFloat(anim.m) + parseFloat(ani.m);
                    }else{
                      findDraw.plays.push(ani);
                    }
                  })
                  vm.onClearAnimalitos();
                }else{
                  draw.plays = getAnimalitos();
                  vm.drawsWithPlays.push(draw);
                }
              }
            });
            if(window.mobileAndTabletcheck()){
              nextStep();
            }
            vm.onClearAnimalitos();
          }else{
            toastr.warning('Seleccione un animalito');
          }
        }else{
          toastr.warning('Agregue un monto para la jugada.', 'Por favor espere!');
        }
      }else{
        toastr.warning('Seleccione un sorteo.', 'Por favor espere!');
      }
      calculateTotal();
    }
  }


  function sale(){
    if(parseInt(vm.config_lot.mmt) > parseInt(vm.total.monto) ){
      $window.swal('Aviso',"Monto minimo del ticket: " +vm.prettyAmount(vm.config_lot.mmt), 'error');
      return
    }
    var i = 1;

    vm.sendData = true;

    vm.plays = angular.copy(
      _.reduce(vm.drawsWithPlays, function(memo, data){
        memo.push({
          c: data.id.toString(),
          j: data.plays
        })
        return memo;
      },[])
    )

    _.forEach(vm.plays, function(draw){
      _.forEach(draw.j, function(play){
        // delete play.animalito;
        play.i = i;
        i++;
      })
    })

    var data = {
      fec: moment().format('DD/MM/YY'),
      ced: vm.currentUser.rif,
      nom: vm.currentUser.nombre,
      ts: 1,
      correo: vm.currentUser.correo,
      jug: vm.plays,
      compress: false,
      ncanje: 0,
      mcanje: 0,
      cupon: "",
      usa_cupon: false,
      app: 1,
      jp: "H",
      ani: true,
      tip: "N",
      uti: 0,
      cod: vm.currentUser.id,
      ven: 1,
      ani_tipo: 0,
      producto_id: vm.producto_id,
      beneficiencia: 'LOTERIA DEL ZULIA',
      cda: true,
      cajero_id: vm.currentUser.tipo == 5? vm.currentUser.cajero.id : null
    }


    if(vm.cupon != null && vm.total.monto > vm.cupon.disponible){
      $window.swal({
        html: 'Modifique la juagada si desea usar el bono exacto. <br>'+
        '<strong>Monto disponible:</strong>'+vm.cupon.disponible +'<br>',
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
        allowOutsideClick: false,
      }).then(function(result){
        if (result.value) {
          $animalitoService.sale(data)
          .then(function(res){
            vm.sendData = false;
            completeSale(res);
          }, function(err){
            toastr.error(err.data.message);
          })
        }else{
          vm.sendData = false;
          return;
        }
      })
    }else{
      $animalitoService.sale(data)
      .then(function(res){
        vm.sendData = false;
        completeSale(res);
      }, function(err){
        toastr.error(err.data.message);
      })
    }
  }

  function completeSale(res) {
    switch (res.cmd) {
      case "C11":
      var modal = $uibModal.open({
        animation: true,
        component: 'modalConfirmPlaysZoologico',
        backdrop: 'static',
        windowClass: 'modalConfirmPlaysZoologico',
        size: 'md',
        resolve: {
          plays: function() {
            return angular.copy(vm.plays);
          },
          playsDenied: function() {
            return res;
          }
        },
      });

      modal.result.then(function(res){
        vm.aceptPlays(res);
        vm.clearPlays();
      }, function(err){
        vm.cancelPlay(res);
        vm.clearPlays();
      })
      break;
      case "C12":
      toastr.error('Las Jugadas Seleccionadas no tienen ningun limite','Error');
      vm.clearDraws();
      vm.clearPlays();
      break;
      case "C10":
      vm.clearPlays();
      vm.clearDraws();
      vm.total = undefined;
      $rootScope.$broadcast('updateCajeroUser',res.cajero_web);
      if(res.cupon != null){
        $rootScope.$broadcast('updateCupon', res.cupon);
      }
      vm.ticket = $sce.trustAsHtml(angular.copy(res.ticket.replace(/\\n/g, '<br>')));
      vm.ticket_ws = angular.copy(res.ticket.replace(/\\n/g, '<br>'));
      // vm.ticket_print = angular.copy(res.ticket.replace(/\n|\r/g, '\n'));
      vm.ticket_print = res.ticket;
      $timeout(function(){
        vm.showTicket(vm.ticket, vm.ticket_print, vm.ticket_ws)
      },0)

      if(window.mobileAndTabletcheck()){
        vm.step1 = true;
        vm.step2 = false;
      }
      break;
      default:

    }
  }

  function showTicket(ticket, ticket_print,ticket_ws){
    var modal = $uibModal.open({
      animation: true,
      component: 'gModalTicket',
      backdrop: 'static',
      windowClass: 'modalTicket',
      size: 'xs',
      resolve: {
        ticket: function() {
          return ticket;
        },
        ticket_print: function(){
          return ticket_print;
        },
        ticket_ws: function(){
          return ticket_ws;
        }
      },
    });
  }

  function aceptPlays(data){
    vm.clearPlays();
    var data = {
      cod: vm.currentUser.id,
      ven: 1,
      ticket: data.nt,
      jp: "H",
      producto_id: vm.producto_id,
      cajero_id: vm.currentUser.tipo == 5? vm.currentUser.cajero.id : null
    }
    $animalitoService.confirmSaleZoo(data)
    .then(function(res){
      completeSale(res);
      if(window.mobileAndTabletcheck()){
        vm.step1 = true;
        vm.step2 = false;
      }
    })
  }

  function cancelPlay(data){
    var data = {
      cod: vm.currentUser.id,
      ven: 1,
      ticket: data.nt,
      jp: "H"
    }
    $animalitoService.reverTicket(data)
    .then(function(res){
      // toastr.info('Jugada Devuelta');
      if(window.mobileAndTabletcheck()){
        vm.step1 = true;
        vm.step2 = false;
      }
    })
  }

  function getAnimalitos(){
    var animalitos = _.reduce(vm.animalitos, function(memo, animalito){
      if(animalito.selected){
        memo.push({
          i: null,
          n: animalito.number,
          m:angular.copy(vm.amount),
          figura: animalito
        })
      }
      return memo;
    },[]);
    return animalitos;
  }

  function checkDrawSelected(){
    var i = 0;
    _.forEach(vm.drawsZoo, function(draw){
      if(draw.checked){
        i++
      };
    });

    return i > 0 ? true : false;

  }

  function deleteAnimal(draw, animal, index){
    draw = _.findWhere(vm.drawsWithPlays, {id: draw});
    draw.plays.splice(index, 1);

    if(draw.plays.length == 0){
      vm.drawsWithPlays.splice(draw, 1);
    }
    calculateTotal();
  }

  function deleteDraw(draw){
    vm.drawsWithPlays.splice(draw, 1);
    calculateTotal();
  }

  function clearAnimalitos(){
    _.forEach(vm.draws, function(draw){
      draw.checked = false;
    })
    _.forEach(vm.animalitos, function(animalito){
      animalito.selected = false;
    })
    vm.amount = undefined;
  }

  function checkAnimalitos(){
    var i = 0;
    _.forEach(vm.animalitos, function(animal){
      if(animal.selected){
        i++
      };
    });

    if(i > 0){ return true; };

    return false;
  }

  function clearPlays(){
    vm.drawsWithPlays = [];
    clearAnimalitos();
    clearDraws();
    checkCoupons();
    if(window.mobileAndTabletcheck()){
      vm.step2 = false;
      $timeout(function(){
        vm.step1 = true;
      },0)
    }
  }
  function clearDraws(){
    _.forEach(vm.drawsZoo, function(res){
      res.checked = false;
    })
  }

  function nextStep(){
    if(vm.step1){
      vm.step1 = false;
      $timeout(function(){
        vm.step2 = true;
      },0)
      return
    }

    // if(vm.step2){
    //   vm.step2 = false;
    //   $timeout(function(){
    //     vm.step2 = true;
    //   },0)
    //   return;
    // }
  }

  vm.continuePlaying = function(){
    vm.amount = undefined;
    
    if(window.mobileAndTabletcheck()){
      vm.step2 = false;
      setTimeout(function(){
        vm.step1 = true;
      })
    }
    _.forEach(vm.drawsZoo, function(data){
      data.checked = false;
    })

    _.forEach(vm.animalitos, function(data){
      data.selected = false;
    })
  }

  function calculateTotal(){
    vm.total = _.reduce(vm.drawsWithPlays, function(memo, data){
      _.forEach(data.plays, function(play){
        memo['monto'] += parseFloat(play.m);
      })
      memo['jugadas'] += data.plays.length;
      return memo;
    },{monto: 0, jugadas: 0});
  }

  function checkCoupons(){
    $cashierService.getCoupon()
    .then(function(res){
      vm.cupon = res;
      // if(vm.drawsZoo.length == 0){
      //   // vm.notBalance = true;
      //   // vm.errorMessage = 'Taquilla cerrada';
      // }else{
      //   // if(parseFloat(vm.currentUser.cajero.saldo_actual) == 0 && vm.cupon == null){
      //   //   vm.notBalance = true;
      //   //   vm.errorMessage = 'Recarge su saldo para continuar jugando';
      //   // }
      // }
    })
  }

  function checkDraws(){
    if(vm.drawsZoo.length > 0){
      $interval(function(){
        var currentHora = parseInt(moment().format('Hmm'))
        _.forEach(vm.drawsZoo, function(data, index){
          var horaSorteo = parseInt(data.horac.split(':').join(''))
          if(horaSorteo < currentHora){
            vm.drawsZoo.splice(index, 1);
          }
        })
      },1000)
    }
  }

  vm.prettyAmount = function(amount){
    return accounting.formatMoney(amount, '',',','.')
  }

  function validateAmountMult(){
    var isValid = parseInt(vm.amount) % vm.config_lot.mt;
    if(isValid == 0){
      return true
    }else{
      $window.swal('Aviso',"La jugada debe ser multriplo de "+vm.prettyAmount(vm.config_lot.mt), 'error');
      return
    }
  }

  // checkDraws();



}
