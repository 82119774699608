angular
.module('app')
.component('miniGranjita', {
  template: '<div ng-include="$ctrl.urlTemplate"></div>',
  controller: MiniGranjitaController,
  bindings: {
    drawsZoo: '=',
    drawsGranjita: '=',
    animalitosGranjita: '=',
    drawsWithPlays: '=',
    products: '=',
    onAddPlays: '&',
    onSelectDrawByProduct: '&',
    amount: '=',
    onSale: '&',
    onClearAnimalitos: '&',
  }
});

MiniGranjitaController.$inject = ['user', 'animalitoService', 'miscelaneos'];

function MiniGranjitaController($user, $animalitoService, $miscelaneos) {
  var vm = this;
  vm.color = '#54a23f';
  vm.countDraw;
  vm.amount = undefined;
  vm.plays = [];
  vm.step1 = true;
  vm.step2 = false;
  vm.step3 = false;
  vm.showAnimals = true;
  vm.drawsWithPlays = [];
  vm.producto_id = $miscelaneos.getProductIdByGame('Granjita');
  vm.date = moment().format('DD/MM/YY');
  var day = moment().format('d');
  var hour = moment().format('H:mm');


  vm.$onInit = function(){
    console.log(vm.animalitosGranjita,'vm.animalitosGranjita')
    vm.loading = true;
    vm.currentUser = $user.getCurrentUser();

    if(window.orientationScreen()){
      vm.urlTemplate = 'app/components/granjita/granjita-portrait.html';
    }else{
      vm.urlTemplate = 'app/components/granjita/mini-granjita.html';
    }
  }


  vm.executeAddPlays = function() {
    vm.onAddPlays();
  };

  vm.handleSelectDrawByProduct = function(draw) {
    vm.onSelectDrawByProduct({ product: draw });
  };

  vm.handleClearAnimals = function(){
    vm.onClearAnimalitos();
  }

}
